import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import router from './router/router.js'
import {localData, sessionData} from "../src/storage/storage"
import http from './router/http'
import echarts from 'echarts'
import cookie from 'vue-cookie'
import tips from './util/util'
import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(SlideVerify)
Vue.prototype.localData = localData;
Vue.prototype.sessionData=sessionData;
Vue.prototype.axios = http
Vue.prototype.$echarts = echarts
Vue.prototype.$cookie = cookie
Vue.prototype.tips = tips;
new Vue({
  el: '#app',
  router,
  render: h => h(App)
});
